/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { use100vh } from "react-div-100vh";
import { HomeBannerTub, Button, Go, Grid } from "~components";
import { useScroll, useWindowDimensions } from "~hooks";
import { MEDIA_QUERIES } from "~utils/helpers";

import { ReactComponent as Arrow } from "~assets/icons/arrow.svg";

//

const BACKGROUND_THRESHOLD_LOWER = 0.25;
const BACKGROUND_THRESHOLD_UPPER = 0.5;

const OVERLAY_THRESHOLD = 0.5;
const OVERLAY_THRESHOLD_MOBILE = 0.65;

const Container = styled.div`
  width: 100%;
  position: relative;
  z-index: 10;
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const MainHeadingWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  height: calc(100vh - 4rem);
  position: sticky;
  top: 3rem;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 0 8.3333%;
  transition: opacity 0.3s var(--cubic-easing);

  ${MEDIA_QUERIES.desktop} {
    top: 4rem;
  }
`;

const MainHeading = styled.h1`
  @keyframes heading-appear {
    0% {
      transform: translate3d(0, -1rem, 0);
      opacity: 0;
    }
    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  animation: 1.5s var(--cubic-easing) heading-appear forwards;
  animation-delay: 0.5s;

  transform: translate3d(0, -1rem, 0);
  opacity: 0;

  text-align: center;
  text-transform: uppercase;
  white-space: pre-line;
`;

const TubWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 20;
`;

const ButtonOverlay = styled.div`
  width: 100%;
  height: 100vh;
  position: sticky;
  top: 3rem;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  z-index: 30;
  transition: opacity 0.3s var(--cubic-easing);

  ${MEDIA_QUERIES.desktop} {
    top: 4rem;
  }
`;

const ButtonWrapper = styled.article`
  width: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 3.75rem;

  ${MEDIA_QUERIES.desktop} {
    align-items: center;
    padding-bottom: 0;
  }
`;

const ButtonContainer = styled.div`
  grid-column: 1 / -1;

  ${MEDIA_QUERIES.desktop} {
    max-width: 80%;
    padding: 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const ButtonHeading = styled.h3`
  margin-bottom: 2.5rem;
  text-transform: uppercase;

  ${MEDIA_QUERIES.desktop} {
    margin-bottom: 2rem;
  }
`;

const ButtonParagraph = styled.p`
  margin-bottom: 2.5rem;

  ${MEDIA_QUERIES.desktop} {
    margin-bottom: 2rem;
  }
`;

const TubBanner = ({
  data: {
    primaryColor,
    secondaryColor,
    heading,
    leftTitle,
    leftHeading,
    leftText,
    leftbutton,
    rightTitle,
    rightHeading,
    rightText,
    rightbutton
  }
}) => {
  // todo : temporary
  let i;
  return <></>;
  // ===========================================================================
  // context / ref / state

  const { scrollTop } = useScroll();
  const { windowSize } = useWindowDimensions();
  const { height } = windowSize;
  const { isDesktop } = useBreakpoint();

  const height100 = use100vh();

  const [scrollScale] = useState(3);

  const [progress, setProgress] = useState(0);

  const [backgroundXTransform, setBackgroundXTransform] = useState(0);
  const [scrollThreshold, setScrollThreshold] = useState(0);

  const [overlayButtons, setOverlayButtons] = useState(false);
  const [leftOverlayActive, setLeftOverlayActive] = useState(false);
  const [rightOverlayActive, setRightOverlayActive] = useState(false);

  // ===========================================================================
  // lifecycle

  // useEffect(() => {
  //   if (isDesktop) {
  //     setScrollScale(3);
  //   } else {
  //     setScrollScale(2);
  //   }
  // }, [isDesktop]);

  useEffect(() => {
    setScrollThreshold(
      height100 ? height100 * scrollScale : height * scrollScale
    ); // 3 would change if 300vh changed
  }, [height100, height]);

  useEffect(() => {
    let scrollProgress = scrollTop / scrollThreshold;

    if (scrollProgress > 1) {
      scrollProgress = 1;
    }

    if (scrollProgress < 0) {
      scrollProgress = 0;
    }

    setProgress(scrollProgress);
  }, [scrollTop]);

  useEffect(() => {
    if (progress < BACKGROUND_THRESHOLD_LOWER) {
      setBackgroundXTransform(0);
      return;
    }
    if (progress > BACKGROUND_THRESHOLD_UPPER) {
      setBackgroundXTransform(1);
      return;
    }

    const normalizedProgress = progress - BACKGROUND_THRESHOLD_LOWER;
    const transformX =
      normalizedProgress /
      (BACKGROUND_THRESHOLD_UPPER - BACKGROUND_THRESHOLD_LOWER);

    setBackgroundXTransform(transformX);
  }, [progress]);

  useEffect(() => {
    if (isDesktop) {
      if (progress < OVERLAY_THRESHOLD) {
        setOverlayButtons(false);
      }
      if (progress > OVERLAY_THRESHOLD) {
        setOverlayButtons(true);
      }
    } else {
      if (progress < OVERLAY_THRESHOLD_MOBILE) {
        setOverlayButtons(false);
      }
      if (progress > OVERLAY_THRESHOLD_MOBILE) {
        setOverlayButtons(true);
      }
    }
  }, [progress]);

  // useEffect(() => {
  //   console.log(`backgroundXTransform`, backgroundXTransform);
  // }, [backgroundXTransform]);

  // useEffect(() => {
  //   console.log(`progress`, progress);
  // }, [progress]);

  // ===========================================================================
  // render

  return (
    <>
      <Container
        css={css`
          height: ${scrollScale * 100}vh;
        `}
      >
        <Background
          css={css`
            transform: translate3d(0, ${
              backgroundXTransform * (-50 / scrollScale)
            }%, 0);

            ${MEDIA_QUERIES.desktop} {
              transform: translate3d(${backgroundXTransform * -50}%, 0, 0);
            }

            background: ${primaryColor?.hex || `#000000`};0
          `}
        />

        <MainHeadingWrapper
          css={css`
            opacity: ${progress > BACKGROUND_THRESHOLD_LOWER ? 0 : 1};
          `}
        >
          <MainHeading
            className="d3"
            css={css`
              color: ${secondaryColor?.hex || `#ffffff`};
            `}
          >
            {heading}
          </MainHeading>

          <div
            css={css`
              animation: 1.5s var(--cubic-easing) heading-appear forwards;
              animation-delay: 2s;

              transform: translate3d(0, -1rem, 0);
              opacity: 0;

              //

              width: 100%;
              position: absolute;
              right: 0;
              bottom: 0;
              left: 0;
              z-index: 10;
              pointer-events: none;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              color: var(--color-white);
            `}
          >
            {/* <p className="b3">Scroll down</p> */}

            <Arrow
              css={css`
                @keyframes arrow-bounce {
                  0% {
                    transform: translate3d(0, -0.25rem, 0);
                  }
                  50% {
                    transform: translate3d(0, 0.25rem, 0);
                  }
                  100% {
                    transform: translate3d(0, -0.25rem, 0);
                  }
                }

                animation: 3s ease-in-out arrow-bounce infinite;

                width: 2rem;
                margin: 0.25rem 0 2rem;
              `}
              stroke="white"
            />
          </div>
        </MainHeadingWrapper>

        <TubWrapper>
          <HomeBannerTub progress={progress} scrollScale={scrollScale} />
        </TubWrapper>

        <ButtonOverlay
          css={css`
            @keyframes button-appear {
              0% {
                transform: scale(1.05);
                opacity: 0;
              }
              100% {
                transform: scale(1);
                opacity: 1;
              }
            }

            opacity: ${overlayButtons ? `1` : `0`};
            pointer-events: ${overlayButtons ? `all` : `none`};
          `}
          aria-hidden={!overlayButtons}
        >
          <ButtonWrapper>
            {(isDesktop && (
              <>
                {(!leftOverlayActive && (
                  <div
                    css={css`
                      animation: 0.3s ease button-appear forwards;
                    `}
                  >
                    <Button
                      color="light"
                      onClick={() => setLeftOverlayActive(true)}
                      text={leftTitle}
                      type="big"
                    />
                  </div>
                )) || (
                  <ButtonContainer
                    css={css`
                      animation: 0.3s ease button-appear forwards;

                      color: ${secondaryColor?.hex || `#ffffff`};
                    `}
                    onMouseLeave={() => setLeftOverlayActive(false)}
                  >
                    <ButtonHeading className="h2">{leftHeading}</ButtonHeading>
                    <ButtonParagraph className="b1">{leftText}</ButtonParagraph>
                    <Go to={leftbutton.link}>
                      <Button
                        text={leftbutton.text}
                        color={isDesktop ? `light` : `dark`}
                      />
                    </Go>
                  </ButtonContainer>
                )}
              </>
            )) || (
              <Button
                _css={css`
                  max-width: 100%;
                  margin-left: 1rem;
                  margin-right: 0.25rem;
                `}
                className={leftOverlayActive ? `hover` : ``}
                text={leftTitle}
                type="big"
                color={isDesktop ? `light` : `dark`}
                onClick={() => {
                  setLeftOverlayActive(!leftOverlayActive);
                  setRightOverlayActive(false);
                }}
              />
            )}
          </ButtonWrapper>

          <ButtonWrapper>
            {(isDesktop && (
              <>
                {(!rightOverlayActive && (
                  <div
                    css={css`
                      animation: 0.3s ease button-appear forwards;
                    `}
                  >
                    <Button
                      onClick={() => setRightOverlayActive(true)}
                      text={rightTitle}
                      type="big"
                    />
                  </div>
                )) || (
                  <ButtonContainer
                    css={css`
                      animation: 0.3s ease button-appear forwards;

                      color: ${primaryColor?.hex || `#000000`};
                    `}
                    onMouseLeave={() => setRightOverlayActive(false)}
                  >
                    <ButtonHeading className="h2">{rightHeading}</ButtonHeading>
                    <ButtonParagraph className="b1">
                      {rightText}
                    </ButtonParagraph>
                    <Go to={rightbutton.link}>
                      <Button text={rightbutton.text} />
                    </Go>
                  </ButtonContainer>
                )}
              </>
            )) || (
              <Button
                _css={css`
                  max-width: 100%;
                  margin-left: 0.25rem;
                  margin-right: 1rem;
                `}
                className={rightOverlayActive ? `hover` : ``}
                text={rightTitle}
                type="big"
                onClick={() => {
                  setRightOverlayActive(!rightOverlayActive);
                  setLeftOverlayActive(false);
                }}
              />
            )}
          </ButtonWrapper>
        </ButtonOverlay>
      </Container>

      {!isDesktop && (
        <Grid
          _css={css`
            padding-bottom: 3.75rem;
          `}
        >
          {leftOverlayActive && (
            <ButtonContainer
              css={css`
                color: ${primaryColor?.hex || `#000000`};
              `}
            >
              <ButtonHeading className="h2">{leftHeading}</ButtonHeading>
              <ButtonParagraph className="b1">{leftText}</ButtonParagraph>
              <Go
                to={leftbutton.link}
                _css={css`
                  width: 100%;
                `}
              >
                <Button
                  text={leftbutton.text}
                  color={isDesktop ? `light` : `dark`}
                />
              </Go>
            </ButtonContainer>
          )}

          {rightOverlayActive && (
            <ButtonContainer
              css={css`
                color: ${primaryColor?.hex || `#000000`};
              `}
            >
              <ButtonHeading className="h2">{rightHeading}</ButtonHeading>
              <ButtonParagraph className="b1">{rightText}</ButtonParagraph>
              <Go
                to={rightbutton.link}
                _css={css`
                  width: 100%;
                `}
              >
                <Button text={rightbutton.text} />
              </Go>
            </ButtonContainer>
          )}
        </Grid>
      )}
    </>
  );
};

export default TubBanner;

// todo : in Sanity
export const query = graphql`
  fragment TubBannerFragment on SanityTubBanner {
    _type

    primaryColor {
      hex
      title
    }

    secondaryColor {
      hex
      title
    }

    heading

    leftTitle
    leftHeading
    leftText
    leftbutton {
      link
      text
    }

    rightTitle
    rightHeading
    rightText
    rightbutton {
      link
      text
    }
  }
`;
